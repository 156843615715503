import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Business Reforms 2023",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      filesrcs: [
        { title: 'PROCEDURE FOR RIGHT OF WAY', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-RIGHT-OF-WAY', time:'Dec 29, 2023' },
        // 29/12/2023
        { title: 'PROCEDURE FOR CONTRACTORS REGISTRATION', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-CONTRACTORS-REGISTRATION', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR OBTAING BUILDING APPROVAL', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAING-BUILDING-APPROVAL', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR OBTAINING FOREST PRODUCE PERMIT', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAINING-FOREST-PRODUCE-PERMIT1', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR OBTAINING WASTE MANAGEMENT SERVICES’ APPROVAL', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Waste-Management-Services’-Approval1', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR PROCESSING COFO AND DATA', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-PROCESSING-COFO-AND-DATA', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR PROCESSING ROFO', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-PROCESSING-ROFO', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR THE REGISTRATION OF BUSINESS PREMISES', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-the-Registration-of-Business-Premises', time:'Dec 28, 2023' },
        { title: 'PROCEDURE FOR THE REGISTRATION OF PRIVATE SCHOOLS', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-THE-REGISTRATION-OF-PRIVATE-SCHOOLS', time:'Dec 28, 2023' },
        { title: 'PROCEDURE TO ACCESS PUBLIC WATER', src: 'https://api.nasida.na.gov.ng/downloads/', time:'Dec 28, 2023' },
        { title: 'PROCEDURES OF PAYMENT OF FEES AND LEVIES FOR INTER-STATE TRADE', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURES-OF-PAYMENT-OF-FEES-AND-LEVIES-FOR-INTER-STATE-TRADE', time:'Dec 28, 2023' },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
